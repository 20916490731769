import {bWebUrl, cSetWebUrl, cWebUrl} from "@/enumbag/StyleEnum";

// 前台台路由
export const frontRouter = [

    {path: '/test-from', name: 'testForm', component: () => import('@/views/Test/index.vue')},
   
    {path: '/testOrder', name: 'testOrder', component: () => import('@/views/Test/orderDetail.vue')},

    // 前台个人中心页面
    {path: '/testProfile', name: 'testProfile', component: () => import('@/clientView/profile/index.vue')},
    // 前台登录
    {path: '/auth/login', name: 'auth-login', component: () => import('../clientView/auth/auth-login')},

    // C端 用户售后提交 查询 分享
    // {path: cSetWebUrl, name: 'clientele', component: () => import('../clientView/ticket-c/save/FromClienteleVeiw')},
    {path: cSetWebUrl, name: 'clientele', component: () => import('@/clientView/ticket-c/save/NewFromClienteleVeiw.vue')},


    {path: '/ticket/:selcetUrl', name: 'selectAfter', component: () => import('../clientView/ticket-c/select/selectVeiw')},
    {path: '/ticket/share/:shareUrl', name: 'shareAfter', component: () => import('../clientView/ticket-c/share/shareView')},
    
    // B端 经销商售后提交 查询
    // {path: bWebUrl, name: 'sidebClientele', component: () => import('../clientView/ticket-b/save/sidebView')},
    {path: bWebUrl, name: 'sidebClientele', component: () => import('@/clientView/ticket-b/save/newSidebView.vue')},


    {path: '/distributor/ticket/:selcetUrl', name: 'selectSideAfter', component: () => import('../clientView/ticket-b/select/selectSideView')},
   
    // 开放端 自助服务 查询
    {path: '/', redirect: cWebUrl},
    // {path: cWebUrl, name: 'issueRuleClientele', component: () => import('../clientView/ticket-side/issueRuleView')},
    {path: cWebUrl, name: 'issueRuleClientele', component: () => import('@/clientView/ticket-side/newIssueRuleView.vue')},


    {path: '/q&a', name: 'selectIssueRule', component: () => import('../clientView/ticket-side/selectIssueRuleView')},
    // {path: '/q&a', name: 'selectIssueRule', component: () => import('@/clientView/ticket-side/NewSelectIssueRuleView.vue')},

    {
        path:'/formPage',
        name:'formPage',
        component: () => import('../components/common/IFiPage/index.vue')
    }
]  
 
/**
 * 开放页路由
 */
export const frontRouterList = [
    // C端 用户售后提交
    cSetWebUrl, '/ticket', '/ticket/share',
    // B端 经销商售后提交
    bWebUrl, '/distributor/ticket',
    // 支持 页面
    // '/q&a',
]
