/**
 * require.context
 * @param {String} directory  读取文件的路径
 * @param {Boolean} useSubdirectories  是否遍历文件的子目录
 * @param {RegExp} regExp  匹配文件的正则
 */
// require.context(directory, useSubdirectories = false, regExp);

// 假设 assets/js 文件夹下有 test.js 文件
// 匹配以 .js 结尾的文件
const files = require.context('./', true, /zh_CN.js$/) 


let BootStrap_zh_CN = {};
// 遍历输出匹配结果
files.keys().forEach(path=> {
    
    var content = files(path).default // 获取文件 export default 内容
    // console.log(content)
    let rootReg= /^\.([\d\D]*?)\/zh_CN.js$/
    let ParentPath= rootReg.exec(path)[1]
    if(!ParentPath) return  // 根目录 不处理
    let ParentPathArray=ParentPath.split('/').map(pathItem=>{
        //将-后面的第一个字符转换为大写，并且去除字符串中的-
        return pathItem.replace(/-(\w)/,function(all,letter){
            return letter.toUpperCase()
        })
    })
    ParentPathArray.reduce((preVal,curVal,curIndex,arr)=>{
       if(!arr[curIndex]) return preVal
       if(curIndex==arr.length-1){
           preVal[curVal]? preVal[curVal]={...preVal[curVal],...content}:preVal[curVal]=content
    
       }else{
           preVal[curVal]??(preVal[curVal]={})
       } 
        return preVal[curVal]
    },BootStrap_zh_CN)
    
 
})

export default {
    ...BootStrap_zh_CN,
}